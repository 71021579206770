@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body::-webkit-scrollbar,
  .changeScrollbar::-webkit-scrollbar {
    @apply w-2 cursor-pointer;
  }

  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  body::-webkit-scrollbar-thumb,
  .changeScrollbar::-webkit-scrollbar-thumb {
    @apply bg-black rounded-md cursor-pointer;
  }

  html,
  body {
    @apply p-0 bg-cream  w-screen overflow-x-hidden;
    cursor: url('/img/coco-head.png'), default;
  }

  * {
    box-sizing: border-box;
  }
  /* Nav */
  .row .three {
    padding: 80px 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #2c3e50;
    color: #ecf0f1;
    text-align: center;
  }

  .hamburger .line {
    //width: 50px;
    //height: 5px;
    //background-color: #ecf0f1;
    //display: block;
    //margin: 8px auto;
    //-webkit-transition: all 0.3s ease-in-out;
    //-o-transition: all 0.3s ease-in-out;
    //transition: all 0.3s ease-in-out;
  }

  .hamburger:hover {
    cursor: pointer;
  }
  .one {
    width: 6.866%;
  }

  #hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
  }

  #hamburger-1.is-active .line:nth-child(1) {
    -webkit-transform: translateY(12px) rotate(45deg);
    -ms-transform: translateY(12px) rotate(45deg);
    -o-transform: translateY(12px) rotate(45deg);
    transform: translateY(12px) rotate(45deg);
  }

  #hamburger-1.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-12px) rotate(-45deg);
    -ms-transform: translateY(-12px) rotate(-45deg);
    -o-transform: translateY(-12px) rotate(-45deg);
    transform: translateY(-12px) rotate(-45deg);
  }

  .coco {
    cursor: url('/img/coco-head.png'), default;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    @apply hidden appearance-none m-0;
  }

  .button {
    @apply inline-block mt-12 font-medium rounded-md text-lg px-6 py-2 bg-black text-cream-light drop-shadow-sm duration-300 ease-in-out hover:scale-95;
  }

  label.rsvp {
    @apply flex flex-row justify-between duration-300 w-full px-4 self-center text-2xl cursor-pointer focus:ring-0 border-2 rounded-md py-4;
  }
}
@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px black;
  }
  .text-shadow-md {
    text-shadow: 4px 4px 8px black;
  }
  .text-shadow-lg {
    text-shadow: 15px 15px 30px black;
  }
  .text-shadow-xl {
    text-shadow: 0px 0px 25px red;
    text-shadow: -4px -4px 15px red;
  }
  .text-shadow-xl::after {
    content: '';
  }
  .text-shadow-none {
    text-shadow: none;
  }
}
